<script lang="ts" setup>
const legalPolicies = useLegalPolicies();
const { legalChecks, legalData, disableAdvertisingCheckbox, displayAdvertisingCheckbox } = storeToRefs(legalPolicies);
</script>

<template>
  <section v-if="legalData" class="w-[556px] px-5">
    <ul class="flex flex-col gap-5">
      <AcceptCheckboxQr
        v-if="legalData.termsAndConditions.display"
        v-model="legalChecks.termsAndConditions"
        :text="$t('LEGAL.TERMS_AND_CONDITIONS')"
        :modal-description="legalData.termsAndConditions.description"
        :modal-image="legalData.termsAndConditions.image"
        :required="true"
      />
      <AcceptCheckboxQr
        v-if="legalData.privacyPolicies.display"
        v-model="legalChecks.privacyPolicies"
        :text="$t('LEGAL.PRIVACY_POLICY')"
        :modal-description="legalData?.privacyPolicies.description"
        :modal-image="legalData?.privacyPolicies.image"
        :required="true"
      />
      <label v-if="displayAdvertisingCheckbox">
        <input
          v-model="legalChecks.advertising"
          type="checkbox"
          class="mt-1 h-7 w-7 accent-kiosk-primary"
          :disabled="disableAdvertisingCheckbox"
        />
        <span class="promotion-text">
          {{ legalData.advertising.description }}
        </span>
      </label>
    </ul>
  </section>
</template>

<style scoped>
label {
  @apply flex gap-5 items-start text-2xl;
}

.promotion-text {
  @apply break-words;
  width: -webkit-fill-available;
}
</style>
