<script lang="ts" setup>
import { useDateFormat } from '@vueuse/core';
import { useTimeStore } from '@/common/stores/time';

const timeStore = useTimeStore();
const metadataStoreV2 = useMetadataStoreV2();

const { now } = storeToRefs(timeStore);
const kioskImagesStore = useKioskImagesStore();

const time = useDateFormat(now, 'hh:mm AA');

onBeforeMount(() => {
  if (!metadataStoreV2.kioskSchedules) {
    metadataStoreV2.getMetadata();
  }
});
</script>

<template>
  <section class="relative full-hd">
    <div class="clock">
      {{ time }}
    </div>
    <KioskImage
      image-class="relative full-hd"
      :src="
        kioskImagesStore.customImages64?.standbyBanner
          ?? 'https://storage.googleapis.com/kiosks-production/Defaults/DefaultStandbyBanner.png'
      "
    />
  </section>
</template>

<style scoped>
.clock {
  @apply absolute top-0 right-0 p-4 text-white text-3xl z-10;
}
</style>
