<script lang="ts" setup>
const errorStore = useErrorStore();
const route = useRoute();
</script>

<template>
  <section class="full-hd content">
    <img src="/something-went-wrong.png?url" alt="something-went-wrong" />
    <h1>Aaaah! Something went wrong</h1>
    <div>
      <p>Brace yourself till we get the error fixed.</p>
      <p>
        You may also
        <a :href="`/${route.params.integration}/${route.params.storeId}`" class="text-blue-500 underline">
          refresh
        </a>
        the page or try again later.
      </p>
    </div>

    <details v-if="errorStore.error">
      <summary>Technical details</summary>
      <pre v-if="typeof errorStore.error === 'object'">{{
        JSON.stringify(errorStore.error, null, "\t")
      }}</pre>
      <pre v-else>{{ errorStore.error }}</pre>
    </details>
  </section>
</template>

<style scoped>
.content {
  @apply flex flex-col items-center justify-center p-32 gap-5;
}

h1 {
  @apply text-5xl font-bold text-blue-950 mb-5;
}

p {
  @apply text-3xl;
}

details {
  @apply border-2 border-dashed border-blue-950 p-4 w-full text-2xl;
}

pre {
  @apply text-orange-800 bg-gray-200 p-4 text-wrap overflow-x-auto max-h-[800px];
}
</style>
