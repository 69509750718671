<script setup lang="ts">
defineProps({
  backLabel: {
    type: String,
    required: true,
  },
  backIcon: {
    type: String,
    required: false,
    default: 'redo',
  },
  disableBack: {
    type: Boolean,
    required: false,
    default: false,
  },
  nextLabel: {
    type: String,
    required: false,
    default: '',
  },
  disableNext: {
    type: Boolean,
    required: false,
    default: false,
  },
  showNextButton: {
    type: Boolean,
    required: false,
    default: true,
  },

  buttonsPosition: {
    type: String as PropType<'center' | 'end'>,
    required: false,
    default: 'center',
  },
});

const emit = defineEmits(['backPressed', 'nextPressed', 'catalogPressed']);
</script>

<template>
  <div class="bottom-actions">
    <slot name="prepend" />

    <div
      class="flex flex-grow gap-5"
      :class="[
        { 'justify-center': buttonsPosition === 'center' },
        { 'justify-end': buttonsPosition === 'end' },
      ]"
    >
      <!-- @vue-ignore-->
      <KioskButton
        class="w-full text-black"
        :icon="backIcon"
        :disabled="disableBack"
        :class="{ '!w-auto': !showNextButton }"
        @on-click="emit('backPressed')"
      >
        {{ backLabel }}
      </KioskButton>

      <KioskButton
        v-if="showNextButton"
        :disabled="disableNext"
        color="primary"
        class="w-full"
        @on-click="emit('nextPressed')"
      >
        {{ nextLabel }}
      </KioskButton>
    </div>
  </div>
</template>

<style scoped>
.bottom-actions {
  @apply h-28 bg-white flex justify-between items-center gap-10 w-full shadow-2xl shadow-neutral-700 z-10 fixed bottom-0;
}

.bottom-actions button {
  @apply py-3;
}
</style>
