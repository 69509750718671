<script setup lang="ts">
import { PropType } from 'vue';
import { PaymentMethod } from '@slabcode/hubster-models/enums/hubster';
import { HubsterCreateOrderPayload } from '@slabcode/hubster-models/hubster/payloads/manager_orders';
import { HubsterOrderTotal } from '@slabcode/hubster-models/hubster/payloads/manager_orders/create-order';
import ImageInProgress from '@/assets/images/checkout.gif';
import ImageFirstRejected from '@/assets/images/rejected-checkout.gif';
import ImageRejectedConfirm from '@/assets/images/rejected.gif';
import { Customer } from '../interfaces/customer';
import { FulfillmentMode } from '@/common/enums/FulfillmentMode';

const props = defineProps({
  customer: {
    type: Object as PropType<Customer>,
    required: true,
  },
  orderTotal: {
    type: Object as PropType<Partial<HubsterOrderTotal>>,
    required: true,
  },
});
// TODO: Fail in cash (Double data)
const emit = defineEmits(['retry-cash', 'retry-card', 'cancel-order']);

const { t } = useI18n();
const route = useRoute();
const reset = useReset();
const cartStore = useCartStoreV2();
const multibrandStore = useMultibrandStore();
const { customer } = toRefs(props);
const webhookStore = useWebhookStoreV2();
const metadataStoreV2 = useMetadataStoreV2();
const { triggerAddPaymentInfo, triggerCancelOrder, triggerPurchase } = useGTMEventsComposableV2();

const kioskImagesStore = useKioskImagesStore();
const { customImages64 } = storeToRefs(kioskImagesStore);

const { kioskSettings } = storeToRefs(metadataStoreV2);

const hideCash = computed(() => !kioskSettings.value?.payment.cash);

const {
  orderId,
  jobResponse,
  paymentType,
  paymentInProgress,
  isFirstRejected,
  isPaymentRejected,
  internalError,
  callbackError,
  isSuccessfullyButPOSFails,
} = storeToRefs(webhookStore);

watchEffect(() => {
  const jobRes = jobResponse.value;
  if (!jobRes) return;

  if (jobRes.tickets?.length) {
    jobRes.tickets.forEach((ticket) => {
      printHtml(ticket);
    });
  }
});

/**
 * Finalice the checkout process clear everything and redirect
 */
function checkoutEnds(isSuccess: boolean) {
  if (multibrandStore.multibrand) {
    const { cancelUrl, successUrl } = multibrandStore;
    window.location.href = webhookStore.orderId ? `${successUrl}?orderId=${webhookStore.orderId}` : cancelUrl;
    return;
  }

  if (!isSuccess) {
    triggerCancelOrder({
      items: toGTMCartItems(cartStore.items),
      section: route.name!.toString(),
      isModal: false,
    });
  }

  webhookStore.restartWebhook();
  cartStore.clearCart();
  reset.flushState();
}

/**
 * Retry an order
 */
function retryCard() {
  webhookStore.sendRetry();
  emit('retry-card');
}

/**
 * send order cash
 */
function retryCash() {
  if (callbackError.value) callbackError.value = null;
  webhookStore.sendRetry();

  if (hideCash.value) {
    reset.flushState();
    return;
  }

  emit('retry-cash');
}

const resultText = computed(() => {
  if (paymentType.value === PaymentMethod.CASH) return t('SUCCESS_ORDER.DESCRIPTION');

  return isSuccessfullyButPOSFails.value ? t('SUCCESS_ORDER.POS_FAIL') : t('SUCCESS_ORDER.CARD_DESCRIPTION');
});

const paymentStatus = computed(() => {
  if (!jobResponse.value) return '';
  if (isFirstRejected.value) return t('CHECKOUT.REJECTED_PAY');
  if (isPaymentRejected.value) return t('CHECKOUT.REJECTED_PAY_AGAIN');

  return t('INTERNAL_ERROR');
});

function acceptStatus() {
  if (isFirstRejected.value) {
    retryCard();
    return;
  }

  if (isPaymentRejected.value) retryCash();
  else webhookStore.restartWebhook();
}

const modalImage = computed(() => {
  if (paymentInProgress.value) return ImageInProgress;
  if (isFirstRejected.value) return ImageFirstRejected;
  return ImageRejectedConfirm;
});

const hasBeenSucceded = computed(() => {
  if (!jobResponse.value) return false;
  const { status } = jobResponse.value;

  return status === JobStatus.PAYMENT_IGNORE || status === JobStatus.PAYMENT_SUCCEEDED;
});

watch(orderId, (newValue) => {
  const currentOrder = webhookStore.currentOrder as HubsterCreateOrderPayload;
  if (newValue) {
    triggerPurchase({
      id: newValue?.toString(),
      attempt: webhookStore.retry + 1,
      items: toGTMCartItems(cartStore.items),
      paymentMethod: currentOrder.customerPayments![0].paymentMethod,
      customer: currentOrder.customer!,
      fulfillmentMode: currentOrder.fulfillmentInfo!.fulfillmentMode as FulfillmentMode,
      orderedAt: currentOrder.orderedAt! as unknown as string,
      tableIdentifier: currentOrder.fulfillmentInfo?.tableIdentifier,
      tax: currentOrder.orderTotal.tax!,
      total: currentOrder.orderTotal.total!,
    });

    triggerAddPaymentInfo(
      webhookStore.paymentType!,
      webhookStore.jobResponse?.paymentInfo,
    );
  }
});
</script>

<template>
  <KioskModal
    modal-class="!w-[650px]"
    v-if="callbackError"
  >
    <div class="payment-data">
      <img
        :src="ImageRejectedConfirm"
        class="w-[320px]"
        alt="Pinpad error"
      />

      <h2 class="payment-status">
        Error
      </h2>

      <p class="payment-description">
        {{ callbackError }}
      </p>

      <div class="flex justify-center w-full gap-5 mt-12">
        <KioskButton
          class="w-1/2"
          text-size="small"
          @on-click="checkoutEnds(true)"
        >
          {{ $t('CHECKOUT.CANCEL') }}
        </KioskButton>
        <KioskButton
          class="w-1/2"
          v-if="!hideCash"
          text-size="small"
          color="primary"
          @on-click="retryCash()"
        >
          {{ $t('CHECKOUT.CASH_PAY') }}
        </KioskButton>
      </div>
    </div>
  </KioskModal>

  <KioskLoading
    v-else-if="hasBeenSucceded || ((paymentType === PaymentMethod.CASH) && paymentInProgress)"
    :image-src="customImages64?.loader"
  />

  <KioskModal
    v-else
    modal-class="!w-[600px]"
  >
    <div
      v-if="orderId"
      class="text-center success"
    >
      <h2 class="text-5xl font-semibold">
        {{ t('SUCCESS_ORDER.TITLE') }}
      </h2>

      <p class="my-5 text-4xl">
        {{ t('SUCCESS_ORDER.ORDER') }}
      </p>

      <h1 class="font-semibold text-[200px] text-kiosk-primary leading-tight uppercase">
        {{ orderId }}
      </h1>

      <p class="w-2/3 mx-auto my-5 text-5xl">
        {{ customer.name }}, <span v-html="resultText" />
      </p>

      <KioskButton
        class="mx-auto mt-12"
        color="primary"
        @on-click="checkoutEnds(true)"
      >
        {{ $t('ACCEPT') }}
      </KioskButton>
    </div>

    <div
      v-else
      class="h-auto payment-data"
    >
      <img
        class="w-[320px]"
        :src="modalImage"
        alt="Card Payment image"
      />

      <h2 class="payment-status">
        {{ paymentInProgress ? t('CHECKOUT.GET_CLOSE_CARD') : paymentStatus }}
      </h2>

      <p class="payment-description">
        {{ internalError ? t('SOMETHING_WENT_WRONG') : jobResponse?.message }}
      </p>

      <div
        v-if="!paymentInProgress"
        class="payment-actions"
        :class="{ '!grid-cols-1': hideCash && !isFirstRejected }"
      >
        <KioskButton
          v-if="!hideCash"
          text-size="small"
          class="w-1/2"
          @on-click="retryCash()"
        >
          {{ $t('CHECKOUT.CASH_PAY') }}
        </KioskButton>

        <KioskButton
          v-if="isFirstRejected"
          color="primary"
          text-size="small"
          class="w-1/2"
          @on-click="acceptStatus()"
        >
          {{ $t('RETRY') }}
        </KioskButton>

        <KioskButton
          v-else
          text-size="small"
          class="w-1/2"
          color="primary"
          @on-click="checkoutEnds(false)"
        >
          {{ $t('CHECKOUT.CANCEL') }}
        </KioskButton>
      </div>
    </div>
  </KioskModal>
</template>

<style scoped>
.payment-data {
  @apply flex flex-col gap-8 items-center justify-center h-[648px] bg-white py-10;
}

.payment-status {
  @apply text-[40px] leading-8 text-center tracking-tight font-bold text-neutral-500 uppercase;
}

.payment-description {
  @apply flex flex-col items-center text-center text-balance text-[26px] leading-8 tracking-tight text-neutral-500;
}

.payment-actions {
  @apply flex justify-center w-full gap-5 mt-5;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
