import { BrandSettingsDto } from '@slabcode/kiosks-core/dtos/settings';
import { WeightDict } from '../dictionary/weightDict';

export function useStyleController() {
  const metadataStoreV2 = useMetadataStoreV2();

  const setupFonts = (settings: BrandSettingsDto) => {
    const { source, name } = settings.fonts;
    const link = document.createElement('link');

    link.rel = 'stylesheet';
    link.href = source;
    document.head.appendChild(link);
    document.body.style.fontFamily = name;
  };

  const setupRoot = (settings: BrandSettingsDto) => {
    const { buttons, cards, colors } = settings;
    const root = document.documentElement;
    root.style.setProperty(CssVariables.PRIMARY, hexToRgb(colors.primary));
    root.style.setProperty(CssVariables.SECONDARY, hexToRgb(colors.secondary));
    root.style.setProperty(CssVariables.TERTIARY, hexToRgb(colors.tertiary));
    root.style.setProperty(CssVariables.BACKGROUND, hexToRgb(colors.background ?? '#fff'));
    root.style.setProperty(CssVariables.BTN_GHOST_BG, hexToRgb(buttons.colors.ghost.background));
    root.style.setProperty(CssVariables.BTN_GHOST_TEXT, hexToRgb(buttons.colors.ghost.text));
    root.style.setProperty(CssVariables.BTN_PRIMARY_BG, hexToRgb(buttons.colors.primary.background));
    root.style.setProperty(CssVariables.BTN_PRIMARY_TEXT, hexToRgb(buttons.colors.primary.text));
    root.style.setProperty(CssVariables.BTN_FONT_WEIGHT, WeightDict[buttons.weight]);
    root.style.setProperty(CssVariables.BTN_RADIUS, RadiusDict[buttons.radius]);
    root.style.setProperty(CssVariables.CARDS_RADIUS, RadiusDict[cards.radius]);
  };

  const setup = () => {
    const settings = metadataStoreV2.brandSettings;

    if (!settings) {
      console.warn('You need to fetch the metadata before setting up the root');
      return;
    }

    setupRoot(settings);
    setupFonts(settings);
  };

  return { setup };
}
