export const useGtmStore = defineStore('gtm', () => {
  const gtmTransactionId = ref('');

  const startOrder = () => {
    gtmTransactionId.value = new Date().getTime().toString();
  };

  const endOrder = () => {
    gtmTransactionId.value = '';
  };

  return { gtmTransactionId, startOrder, endOrder };
});
