import { RouteName } from '../../../common/routes/routeName';

export function useFulfillmentController() {
  const router = useRouter();
  const metadataStoreV2 = useMetadataStoreV2();
  const orderTypeStore = useOrderTypeStore();

  /**
   * Determines whether to show the table view based on the current fulfillment mode and kiosk settings.
   * @returns {boolean} Returns true if the table view should be shown, false otherwise.
   */
  const showTableView = (): boolean => {
    const { fulfillmentMode } = orderTypeStore;
    const { kioskSettings } = metadataStoreV2;

    if (!kioskSettings) {
      console.warn('there are not kiosk settings');
      return false;
    }

    const { dineIn, pickUp } = kioskSettings.fulfillment;

    if (fulfillmentMode === FulfillmentMode.DINE_IN && dineIn.settings.table) {
      return true;
    }

    if (fulfillmentMode === FulfillmentMode.PICKUP && pickUp.settings.table) {
      return true;
    }

    return false;
  };

  /*
  * Redirect when you are in order type view
  */
  const redirectFromFulfillment = () => {
    // if categories is disabled
    const redirectTo = metadataStoreV2.brandSettings?.layout.category === CategoryLayouts.Disabled
      ? RouteName.PRODUCTS
      : RouteName.ORDER_CATEGORIES;

    router.push({ name: redirectTo });
  };

  /*
  * Redirect when you are in welcome view
  */
  const redirectFromWelcome = () => {
    const { kioskSettings } = metadataStoreV2;
    if (!kioskSettings) {
      console.warn('there are not kiosk settings');
      return;
    }

    const { dineIn, pickUp } = kioskSettings.fulfillment;

    // Go to order type if both are enabled
    if (dineIn.enabled && pickUp.enabled) {
      router.push({ name: RouteName.ORDER_TYPE });
      return;
    }

    // Assume one of them as unique answer and ignore order type screen
    if (dineIn.enabled) {
      orderTypeStore.fulfillmentMode = FulfillmentMode.DINE_IN;
    }

    if (pickUp.enabled) {
      orderTypeStore.fulfillmentMode = FulfillmentMode.PICKUP;
    }

    redirectFromFulfillment();
  };

  return { redirectFromFulfillment, redirectFromWelcome, showTableView };
}
