import { BrandSettingsDto, ClusterSettingsDto, KioskSettingsDto } from '@slabcode/kiosks-core/dtos/settings';
import { BrandImagesTypes, ClusterImagesTypes } from '@slabcode/kiosks-core/enums';

export const useMetadataStoreV2 = defineStore('metadataV2', () => {
  const loading = ref(false);

  // banners available for the kiosk in first screen
  const banners = ref<string[]>([]);

  // settings available for the brand
  const brandSettings = ref<BrandSettingsDto | null>(null);

  // settings available for the cluster
  const clusterSettings = ref<ClusterSettingsDto | null>(null);

  const errorStore = useErrorStore();

  // Kiosk logos, carousel images, checkout assets, etc...
  const customImages = ref<Record<BrandImagesTypes | ClusterImagesTypes, string> | null>(null);

  // Kiosk standby times to open, close and reopen
  const kioskSchedules = ref<{
    openingTime: string;
    closingTime: string;
    reopeningTime: string;
  } | null>(null);

  // available settings per kiosk
  const kioskSettings = ref<KioskSettingsDto | null>(null);

  const kioskInfoStore = useKioskInfoStore();

  const priceDecimals = computed(() => (clusterSettings.value?.currency.decimals ? 2 : 0));

  const getMetadata = async () => {
    loading.value = true;
    try {
      const metadata = await requests.get(`${kioskInfoStore.storeId}/metadata`, {
        params: {
          timestamp: new Date().toLocaleString('sv'),
        },
      });

      banners.value = metadata.banners;
      brandSettings.value = metadata.brandSettings;
      clusterSettings.value = metadata.clusterSettings;
      customImages.value = metadata.customImages;
      kioskSchedules.value = metadata.kioskSchedules;
      kioskSettings.value = metadata.kioskSettings;
    } catch (err) {
      errorStore.handleAndRedirect(err, 'Error getting metadata info, check your internet connection and kiosk url');
    } finally {
      loading.value = false;
    }
  };

  return {
    getMetadata,
    banners,
    brandSettings,
    clusterSettings,
    customImages,
    kioskSchedules,
    kioskSettings,
    priceDecimals,
  };
});
