<script lang="ts" setup>
import { PropType } from 'vue';
import { ItemDescriptor } from '@slabcode/hubster-modifiers-utils';
import noPhotoSrc from '@/assets/images/noPhoto.jpeg?url';

const props = defineProps({
  item: {
    type: Object as PropType<ItemDescriptor>,
    required: true,
  },
  modifierDecimals: {
    type: Number,
    required: false,
    default: 2,
  },
  showDetails: {
    type: Boolean,
    required: false,
    default: true,
  },
  containerClass: {
    type: String,
    required: false,
    default: '',
  },
  displayCheckbox: {
    type: Boolean,
  },
});

const menuStore = useMenuStore();
const metadataStoreV2 = useMetadataStoreV2();
const { brandSettings } = storeToRefs(metadataStoreV2);
const { item } = toRefs(props);
const readonly = isReadonlyItem(item.value);

const emit = defineEmits(['select', 'customize', 'saveChanges', 'add', 'sub']);

const modifierPrice = computed(() => {
  const { amount } = props.item.itemMeta.price;

  return amount !== 0
    ? formatCurrency(
      menuStore.productsCurrency,
      amount ?? 0,
      props.modifierDecimals,
    )
    : null;
});

function chooseModifier() {
  if (readonly) return;
  emit('select', props.item);
}

function onCustomizeClick() {
  emit('customize', props.item);
}
</script>

<template>
  <div
    type="button"
    class="w-full flex flex-col items-center border-[3px] border-white p-4 pb-8 cursor-pointer h-full justify-between"
    :class="[
      containerClass,
      { 'opacity-50': !item.available },
      'rounded-cards',
    ]"
  >
    <figure @click="chooseModifier()" @keydown.enter="chooseModifier()">
      <div class="relative modifier-image">
        <span class="out-of-stock" v-if="!item.available">{{
          $t("PRODUCTS.OUT_OF_STOCK")
        }}</span>
        <KioskImage
          v-if="item.itemMeta.photoIds[0] && item.itemMeta.photoIds[0].length > 0"
          :src="item.itemMeta.photoIds[0]"
          hide-border
          class="image-size"
        />
        <img
          v-else
          :src="noPhotoSrc"
          alt="modifier"
          class="image-size font-big"
        />
      </div>

      <figcaption
        v-if="showDetails"
        class="flex flex-col items-center justify-center"
      >
        <span
          class="text-3xl min-h-[104px] font-medium leading-8 w-52 text-center py-5 tracking-tight"
        >{{ item.itemMeta.name }}</span>
        <span
          v-if="modifierPrice"
          class="text-4xl min-h-[48px] leading-6 tracking-tight text-neutral-400"
        >
          +{{ modifierPrice }}
        </span>
      </figcaption>
    </figure>

    <KioskButton
      v-if="item.canBeCustomized"
      text-size="small"
      class="z-10 max-w-full mx-auto"
      color="primary"
      :disabled="item.quantity <= 0 || !item.available"
      @on-click="onCustomizeClick()"
    >
      {{ !item.available ? $t("NOT_AVAILABLE") : $t("CUSTOMIZE") }}
    </KioskButton>

    <KioskCounterV2
      v-if="item.hasNumericInput && !readonly"
      class="mx-auto"
      :disable-increase="item.disableAddButton"
      :disable-decrease="item.disableSubButton"
      :disabled="!item.available || item.disabled"
      :button-style="brandSettings?.buttons.counterStyle"
      :quantity="item.quantity"
      @add="$emit('add', item)"
      @sub="$emit('sub', item)"
    />

    <template v-if="readonly && item.hasNumericInput && !item.parent?.isRequired && item.parent?.items.length !== 1">
      <span
        class="flex justify-center w-full text-4xl font-medium"
      >
        {{ item.quantity }}
      </span>
    </template>

    <template v-if="displayCheckbox">
      <label :for="item.itemMeta.id" />

      <input
        type="checkbox"
        class="h-7 accent-kiosk-primary w-7"
        :id="item.itemMeta.id"
        :checked="item.selected"
        @on-click="chooseModifier()"
      />
    </template>
  </div>
</template>

<style scoped>
.image-size {
  @apply h-[220px] w-[220px] mb-2 mx-auto flex flex-col justify-center text-white leading-10 px-4;
}

.out-of-stock {
  @apply text-5xl mx-auto w-full left-0 right-0 text-center absolute mt-[50%] -translate-y-1/2 z-10 uppercase;
}
</style>
