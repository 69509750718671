/* eslint-disable no-unused-vars */
export const enum CssVariables {
  PRIMARY = '--kiosk-primary-color',
  SECONDARY = '--kiosk-secondary-color',
  TERTIARY = '--kiosk-tertiary-color',
  BACKGROUND = '--kiosk-background-color',
  BTN_GHOST_BG = '--kiosk-btn-ghost-bg',
  BTN_GHOST_TEXT = '--kiosk-btn-ghost-text',
  BTN_PRIMARY_BG = '--kiosk-btn-primary-bg',
  BTN_PRIMARY_TEXT = '--kiosk-btn-primary-text',
  BTN_FONT_WEIGHT = '--kiosk-btn-font-weight',
  BTN_RADIUS = '--kiosk-btn-radius',
  CARDS_RADIUS = '--kiosk-cards-radius',
}
