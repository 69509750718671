import { RouteName } from '../../../common/routes/routeName';

export function useKioskSetup() {
  const route = useRoute();
  const router = useRouter();
  const metadataStoreV2 = useMetadataStoreV2();
  const styleController = useStyleController();
  const kioskImagesStore = useKioskImagesStore();
  const languagesStore = useLanguageStore();
  const kioskInfo = useKioskInfoStore();
  const multibrandStore = useMultibrandStore();
  const standbyBannerStore = useStandbyBannerStore();

  const setup = async () => {
    const { storeId, integration } = route.params;

    kioskInfo.storeId = storeId as string;
    kioskInfo.integration = integration as string;

    if (!storeId || !integration) {
      router.replace({ name: RouteName.NOT_FOUND });
    }
    await metadataStoreV2.getMetadata();

    styleController.setup();
    await kioskImagesStore.setup();

    const isMultibrand = multibrandStore.setup();

    if (!isMultibrand) {
      languagesStore.setup();
      standbyBannerStore.setup();
    }
  };

  return { setup };
}
