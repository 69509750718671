<script setup lang="ts">
const route = useRoute();
const cartUtils = useCartUtils();
const menuController = useMenuController();
const languageStore = useLanguageStore();
const metadataStoreV2 = useMetadataStoreV2();
const inactiveStoreV2 = useInactiveStoreV2();
const gtmStore = useGtmStore();

const { triggerCancelIdleModal, triggerStartOrder } = useGTMEventsComposableV2();

function cancelInactivityModal() {
  inactiveStoreV2.resetInactivity();
  // Trigger GTM event
  triggerCancelIdleModal(route.name);
}

/**
 * Activate the inactive modal
 */
async function beforeMount() {
  // Get menu
  const { storeId } = route.params;
  if (!storeId) return;
  await menuController.getMenu(storeId as string, languageStore.currentLanguage);

  // Generate transactionId
  gtmStore.startOrder();
  triggerStartOrder();

  // Start countdown
  if (!metadataStoreV2.brandSettings) return;

  inactiveStoreV2.setup({
    displayModalSeconds: metadataStoreV2.brandSettings.inactivity.screen,
    inactivitySeconds: metadataStoreV2.brandSettings.inactivity.timer,
  });
}

function beforeUnmount() {
  inactiveStoreV2.pauseChecking();
}

onBeforeMount(() => beforeMount());
onBeforeUnmount(() => beforeUnmount());

function pressed() {
  inactiveStoreV2.resetInactivity();
}
</script>

<template>
  <div
    @mousedown="pressed"
    @touchstart="pressed"
    @mouseup="pressed"
    @touchend="pressed"
    @touchcancel="pressed"
  >
    <router-view />
  </div>
  <Teleport to="#inactive-modal">
    <OrderInactivityModal
      v-if="inactiveStoreV2.displayModal"
      :time="inactiveStoreV2.modalCounter"
      @inactive="cartUtils.restartCart(true)"
      @close="cancelInactivityModal()"
    />
  </Teleport>
</template>
