<script lang="ts" setup>
import { formatCurrency } from '@/common/utils/formatCurrency';
import { StepWizardName } from '@/common/enums/stepWizardName';

const router = useRouter();
const metadataStoreV2 = useMetadataStoreV2();
const kioskImagesStore = useKioskImagesStore();
const { customImages64 } = storeToRefs(kioskImagesStore);
const { priceDecimals } = storeToRefs(metadataStoreV2);
const { t } = useI18n();
const { steps, stepIndex } = useStepWizard();
const menuStore = useMenuStore();
const { productsCurrency } = storeToRefs(menuStore);
const cartStoreV2 = useCartStoreV2();
const { items, itemsCount, subtotal } = storeToRefs(cartStoreV2);
const { continueCustomization } = useCustomize();
const { triggerViewCart, triggerGoBack } = useGTMEventsComposableV2();
const route = useRoute();
const { decreaseCartItemQuantity, increaseCartItemQuantity, removeItem } = useCartItemHandler();
const couponStore = useCouponStore();

const showQRModal = ref<boolean>(false);

const totalCart = computed(() => subtotal.value - couponStore.discount);

function goBack() {
  router.back();
  triggerGoBack(route.name);
}

onBeforeMount(() => {
  triggerViewCart(items.value, subtotal.value);
});
</script>

<template>
  <section
    class="sticky top-0 z-20 flex items-center justify-center w-full pb-0 bg-neutral-200"
  >
    <div class="flex items-center w-full px-12 flex-nowrap">
      <KioskTimeline
        v-if="customImages64?.timelineIcon"
        :steps="steps"
        :current-index="stepIndex(StepWizardName.CONFIRM)"
        :active-image="customImages64.timelineIcon"
      />

      <KioskButton color="primary" class="flex-none !h-[68px] w-[108px] !py-2">
        <div class="flex items-center justify-center text-[32px] gap-1">
          <span class="icon icon-shopping-cart" />
          <span
            class="flex items-center justify-center p-1 text-black bg-white h-9 w-9 rounded-xs"
          >{{ itemsCount }}</span>
        </div>
      </KioskButton>
    </div>
  </section>

  <section class="flex flex-col justify-between w-full h-[1690px]">
    <!-- Order total -->
    <div class="relative cart-amount bg-kiosk-tertiary">
      <h5 class="absolute flex items-center justify-center amount-info">
        {{ t("TOTAL") }}:
        <strong class="ml-5" v-if="couponStore.discount === 0"> {{ formatCurrency(productsCurrency, subtotal, priceDecimals) }} </strong>
        <p class="ml-5 line-through" v-if="couponStore.discount > 0">
          {{ formatCurrency(productsCurrency, subtotal, priceDecimals) }}
        </p>
        <strong class="ml-5" v-if="couponStore.discount > 0"> {{ formatCurrency(productsCurrency, totalCart, priceDecimals) }} </strong>
      </h5>
    </div>

    <section class="h-full pb-32 overflow-auto animate__animated animate__fadeInLeft">
      <UpSellingBanners />

      <TransitionGroup name="add-animation">
        <ShoppingCartItemV2
          v-for="(item, i) in items"
          :key="item.item.itemMeta.id"
          :item="item.item"
          :description="item.description"
          :currency="productsCurrency"
          :price-decimals="priceDecimals"
          @add="increaseCartItemQuantity(item.item, i)"
          @sub="decreaseCartItemQuantity(item.item, i)"
          @delete="removeItem(item.item, i)"
          @edit="continueCustomization($event, i)"
        />
      </TransitionGroup>
    </section>

    <CouponActions
      v-if="metadataStoreV2.kioskSettings?.coupons.enabled"
      @show-modal="showQRModal = true"
    />

    <OrderBottomActions
      :disable-next="cartStoreV2.subtotal === 0"
      :back-label="$t('BACK')"
      :next-label="$t('TIMELINE.PAY')"
      @back-pressed="goBack"
      @next-pressed="router.push({ name: 'CheckoutV2' })"
    />
  </section>

  <CouponQRModal
    v-if="showQRModal"
    @cancel="showQRModal = false"
  />
</template>

<style scoped>
.cart-amount {
  @apply relative p-3 h-[92px] w-full text-3xl flex items-center justify-center text-white;
}

.back-button {
  @apply absolute left-5 top-1/2 -translate-y-1/2 w-10 h-10;
}

.text-separator {
  @apply border-r h-10 border-white mx-8 bg-white;
}

.add-animation-enter-active {
  animation: add-slowly 0.5s both;
}
.add-animation-leave-active {
  animation: add-slowly 0.5s both reverse;
}

@keyframes add-slowly {
  from {
    opacity: 0;
    transform: translateY(-70%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
