/* eslint-disable max-lines */
/* eslint-disable vue/max-len */
import { RouteRecordName } from 'vue-router';
import { HubsterCategory } from '@slabcode/hubster-models/hubster/common';
import { HubsterMenuPublishItem } from '@slabcode/hubster-models/hubster/payloads/menu/publish/menuData/item';
import { PaymentMethod } from '@slabcode/hubster-models/enums/hubster';
import { GTMCategory } from '../interfaces/gtmCategory';
import { GTMSelectItem } from '../interfaces/gtmSelectItem';
import { GTMCartItem } from '../interfaces/gtmCartItems';
import { ICartItem } from '@/modules/cart/interfaces/cartState';
import { GTMCustomer } from '../interfaces/gtmCustomer';
import { GTMHistoryItem } from '../interfaces/gtmHistoryItem';
import { PaymentInfoDto } from '../../modules/checkout/interfaces/job-response';
import { FulfillmentMode } from '../enums/FulfillmentMode';

export function useGTMEventsComposableV2() {
  const menuStore = useMenuStore();
  const kioskInfoStore = useKioskInfoStore();
  const gtmStore = useGtmStore();
  const brand = computed(() =>
    kioskInfoStore.integration.toString().toUpperCase().replace('-KIOSK', ''));

  /**
   * ECOMMERCE EVENTS
   */
  function triggerViewItemList(category: GTMCategory) {
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: GTMEvent.VIEW_ITEM_LIST,
      ecommerce: {
        brand: brand.value,
        kiosk: kioskInfoStore.storeId,
        transaction_id: gtmStore.gtmTransactionId,
        item_list_id: category.id,
        item_list_name: category.name,
        currency: menuStore.productsCurrency,
        items: menuStore.products.map((product, index) => ({
          item_id: product.id,
          item_name: product.name,
          index,
          item_list_name: category.name,
          price: product.price.amount,
          quantity: 1,
        })),
      },
    });
  }

  function triggerSelectItem(product: GTMSelectItem, category: GTMCategory) {
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: GTMEvent.SELECT_ITEM,
      ecommerce: {
        brand: brand.value,
        kiosk: kioskInfoStore.storeId,
        transaction_id: gtmStore.gtmTransactionId,
        item_list_id: category.id,
        item_list_name: category.name,
        currency: menuStore.productsCurrency,
        items: [
          {
            item_id: product.id,
            item_name: product.name,
            price: product.price,
            item_list_name: category.name,
            quantity: 1,
          },
        ],
      },
    });
  }

  function triggerAddToCart({
    section,
    item,
    operation,
    category,
  }: {
    section: RouteRecordName;
    category?: GTMCategory;
    item: GTMCartItem;
    operation: string;
  }) {
    const actualCategory = category ?? item.category;
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: GTMEvent.ADD_TO_CART,
      ecommerce: {
        brand: brand.value,
        kiosk: kioskInfoStore.storeId,
        transaction_id: gtmStore.gtmTransactionId,
        value: item.total,
        currency: menuStore.productsCurrency,
        section,
        operation,
        items: [
          {
            item_id: item.id,
            item_name: item.name,
            price: item.total / item.quantity,
            item_list_id: actualCategory.id,
            item_list_name: actualCategory.name,
            item_variant: item.description,
            identifier: item.identifier,
            quantity: item.quantity,
          },
        ],
      },
    });
  }

  function triggerRemoveFromCart({
    product,
    description,
    category,
    removedQuantity: quantity,
    identifier,
  }: {
    product: GTMSelectItem;
    description: string;
    category: GTMCategory;
    removedQuantity: number;
    identifier: string;
  }) {
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: GTMEvent.REMOVE_FROM_CART,
      ecommerce: {
        brand: brand.value,
        kiosk: kioskInfoStore.storeId,
        transaction_id: gtmStore.gtmTransactionId,
        value: product.price,
        currency: menuStore.productsCurrency,
        items: [
          {
            item_id: product.id,
            item_name: product.name,
            price: product.price / product.quantity,
            item_list_id: category.id,
            item_list_name: category.name,
            item_variant: description,
            identifier,
            quantity,
          },
        ],
      },
    });
  }

  function triggerViewCart(cartItems: ICartItem[], total: number) {
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: GTMEvent.VIEW_CART,
      ecommerce: {
        brand: brand.value,
        kiosk: kioskInfoStore.storeId,
        transaction_id: gtmStore.gtmTransactionId,
        currency: menuStore.productsCurrency,
        value: total,
        items: cartItems.map((cartItem, index) => {
          const { item, description, identifier } = cartItem;

          return {
            item_id: item.itemMeta.id,
            item_name: item.itemMeta.name,
            index,
            item_list_id: item.category.id,
            item_list_name: item.category.name,
            price: item.total,
            quantity: item.quantity,
            item_variant: description,
            identifier,
          };
        }),
      },
    });
  }

  function triggerBeginCheckout({
    items,
    attempt,
    paymentMethod,
    customer,
    fulfillmentMode,
    tableIdentifier,
  }: {
    paymentMethod: PaymentMethod;
    customer: GTMCustomer;
    fulfillmentMode: FulfillmentMode;
    tableIdentifier?: string;
    items: GTMCartItem[];
    attempt: number;
  }) {
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: GTMEvent.BEGIN_CHECKOUT,
      ecommerce: {
        brand: brand.value,
        table_number: tableIdentifier ?? '',
        client_name: customer.name ?? '',
        client_email: customer.email ?? '',
        attempt,
        disposition: fulfillmentMode,
        payment_type: paymentMethod,
        currency: menuStore.productsCurrency,
        transaction_id: gtmStore.gtmTransactionId,
        kiosk: kioskInfoStore.storeId,
        items: items.map((item, index) => ({
          item_id: item.id,
          item_name: item.name,
          index,
          item_list_id: item.category.id,
          item_list_name: item.category.name,
          price: item.price,
          quantity: item.quantity,
          item_variant: item.description,
          identifier: item.identifier,
        })),
      },
    });
  }

  function triggerAddPaymentInfo(
    type: PaymentMethod,
    details?: PaymentInfoDto,
  ) {
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: GTMEvent.ADD_PAYMENT_INFO,
      ecommerce: {
        brand: brand.value,
        franchise: details?.franchise ?? '',
        entry_mode: details?.entryMode ?? '',
        account_type: details?.accountType ?? '',
        payment_details: details,
        kiosk: kioskInfoStore.storeId,
        transaction_id: gtmStore.gtmTransactionId,
        payment_type: type,
      },
    });
  }

  function triggerPurchase({
    paymentMethod,
    customer,
    fulfillmentMode,
    tableIdentifier,
    items,
    attempt,
    id,
    orderedAt,
    total,
    tax,
  }: {
    paymentMethod: PaymentMethod;
    customer: GTMCustomer;
    fulfillmentMode: FulfillmentMode;
    tableIdentifier?: string;
    items: GTMCartItem[];
    attempt: number;
    id: string;
    orderedAt: string;
    total: number;
    tax: number;
  }) {
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: GTMEvent.PURCHASE,
      ecommerce: {
        brand: brand.value,
        time: orderedAt,
        order: id,
        table_number: tableIdentifier,
        client_name: customer.name ?? '',
        client_email: customer.email ?? '',
        value: total,
        tax,
        attempt,
        disposition: fulfillmentMode,
        payment_type: paymentMethod,
        currency: menuStore.productsCurrency,
        transaction_id: gtmStore.gtmTransactionId,
        kiosk: kioskInfoStore.storeId,
        items: items!.map((item, index) => ({
          item_id: item.id,
          item_name: item.name,
          index,
          item_list_id: item.category.id,
          item_list_name: item.category.name,
          price: item.price,
          quantity: item.quantity,
          item_variant: item.description,
          identifier: item.identifier,
        })),
      },
    });
  }

  function triggerCancelOrder({
    items,
    section,
    isModal,
  }: {
    items: GTMCartItem[];
    section: string;
    isModal: boolean;
  }) {
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: GTMEvent.CANCEL_ORDER,
      ecommerce: {
        brand: brand.value,
        time: new Date().toLocaleString('sv'),
        section,
        idle_modal: isModal,
        kiosk: kioskInfoStore.storeId,
        transaction_id: gtmStore.gtmTransactionId,
        items: items.map((item, index) => ({
          item_id: item.id,
          item_name: item.name,
          index,
          item_list_id: item.category.id,
          item_list_name: item.category.name,
          price: item.price,
          quantity: item.quantity,
          item_variant: item.description,
          identifier: item.identifier,
        })),
      },
    });
  }

  function triggerScaleUpItem({
    item,
    isEdit,
    value,
    identifier,
  }: {
    item: HubsterMenuPublishItem;
    isEdit: boolean;
    value: number;
    identifier: string;
  }) {
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: GTMEvent.SCALE_UP_ITEM,
      ecommerce: {
        brand: brand.value,
        process: isEdit ? 'edit' : 'add',
        scale_up: value,
        kiosk: kioskInfoStore.storeId,
        transaction_id: gtmStore.gtmTransactionId,
        item_id: item.id,
        item_name: item.name,
        identifier,
      },
    });
  }

  /**
   * CUSTOM EVENTS
   */
  function triggerStartOrder() {
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: GTMEvent.START_ORDER,
      ecommerce: {
        brand: brand.value,
        kiosk: kioskInfoStore.storeId,
        transaction_id: gtmStore.gtmTransactionId,
        time: new Date().toLocaleString('sv'),
      },
    });
  }

  function triggerSelectOrderType(disposition: FulfillmentMode) {
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: 'select_order_type',
      ecommerce: {
        brand: brand.value,
        kiosk: kioskInfoStore.storeId,
        transaction_id: gtmStore.gtmTransactionId,
        disposition,
      },
    });
  }

  function triggerAddTableNumber(value: string) {
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: GTMEvent.ADD_TABLE_NUMBER,
      ecommerce: {
        brand: brand.value,
        kiosk: kioskInfoStore.storeId,
        transaction_id: gtmStore.gtmTransactionId,
        disposition: FulfillmentMode.DINE_IN,
        table_number: value,
      },
    });
  }

  function triggerSelectCategory(category: HubsterCategory) {
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: GTMEvent.SELECT_CATEGORY,
      ecommerce: {
        brand: brand.value,
        category_name: category.name,
        kiosk: kioskInfoStore.storeId,
        transaction_id: gtmStore.gtmTransactionId,
      },
    });
    // Trigger ViewItemList event
    triggerViewItemList(category);
  }

  function triggerIdleModal(section: RouteRecordName) {
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: GTMEvent.IDLE_MODAL,
      ecommerce: {
        brand: brand.value,
        kiosk: kioskInfoStore.storeId,
        transaction_id: gtmStore.gtmTransactionId,
        section,
      },
    });
  }

  function triggerCancelIdleModal(section: RouteRecordName) {
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: GTMEvent.CANCEL_IDLE_MODAL,
      ecommerce: {
        brand: brand.value,
        kiosk: kioskInfoStore.storeId,
        transaction_id: gtmStore.gtmTransactionId,
        section,
      },
    });
  }

  function triggerGoBack(section: RouteRecordName) {
    // @ts-ignore
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer.push({
      event: GTMEvent.GO_BACK,
      ecommerce: {
        brand: brand.value,
        kiosk: kioskInfoStore.storeId,
        transaction_id: gtmStore.gtmTransactionId,
        section,
      },
    });
  }

  // DONE
  function triggerEditVariation({
    history,
    cartItem,
    isEdit,
  }: {
    history: GTMHistoryItem[];
    cartItem: GTMCartItem;
    isEdit: boolean;
  }) {
    history.forEach((historyItem) => {
      // @ts-ignore
      window.dataLayer.push({ ecommerce: null });
      // @ts-ignore
      window.dataLayer.push({
        event: GTMEvent.EDIT_VARIATION,
        ecommerce: {
          brand: brand.value,
          kiosk: kioskInfoStore.storeId,
          transaction_id: gtmStore.gtmTransactionId,
          process: isEdit ? 'edit' : 'add',
          identifier: cartItem.identifier,
          item_id: cartItem.id,
          item_name: cartItem.name,
          item_list_id: cartItem.category.id,
          item_list_name: cartItem.category.name,

          section: historyItem.section,
          modifier_name: historyItem.name,
          modifier_id: historyItem.id,
          level: historyItem.level,
          quantity: historyItem.quantity,
          parent_modifier_name: historyItem.parentName,
          parent_modifier_id: historyItem.parentId,
          price: historyItem.price,
        },
      });
    });
  }

  return {
    triggerViewItemList,
    triggerSelectItem,
    triggerAddToCart,
    triggerRemoveFromCart,
    triggerViewCart,
    triggerBeginCheckout,
    triggerPurchase,
    triggerAddPaymentInfo,
    triggerCancelOrder,
    triggerStartOrder,
    triggerSelectOrderType,
    triggerAddTableNumber,
    triggerSelectCategory,
    triggerIdleModal,
    triggerCancelIdleModal,
    triggerGoBack,
    triggerScaleUpItem,
    triggerEditVariation,
  };
}
