import { FulfillmentMode } from '../../../common/enums/FulfillmentMode';
import { IOrderOption } from '@/modules/order-type/types';

export const useOrderTypeStore = defineStore('orderType', () => {
  const { triggerAddTableNumber } = useGTMEventsComposableV2();
  const { t } = useI18n();
  // State
  const tableNumber = ref<string>('');
  const orderTypes = ref<IOrderOption[]>([
    { icon: 'eat-here', label: 'EAT_HERE', value: FulfillmentMode.DINE_IN },
    { icon: 'shopping-bag', label: 'TAKE_AWAY', value: FulfillmentMode.PICKUP },
  ]);

  const fulfillmentMode = ref<FulfillmentMode>(FulfillmentMode.DINE_IN);

  // Getters
  const isPickupOrder = computed(
    () => fulfillmentMode.value === FulfillmentMode.PICKUP,
  );

  const orderType = computed(() => {
    if (fulfillmentMode.value === FulfillmentMode.PICKUP) {
      return tableNumber.value ? `${t('TAKE_AWAY')} - ${t('TABLE')} ${tableNumber.value}` : t('TAKE_AWAY');
    }

    return tableNumber.value
      ? `${'EAT_HERE'} - ${'TABLE'} ${tableNumber.value}`
      : 'EAT_HERE';
  });

  const orderOptions = computed(() =>
    orderTypes.value.map((type) => ({
      ...type,
      label: type.label,
    })));

  // Actions
  function setOrderOptions(value: IOrderOption[]) {
    orderTypes.value = value;
  }

  function setTableNumber(value: string) {
    tableNumber.value = value.toString();
    // Trigger GTM event
    if (value.length > 0) triggerAddTableNumber(value);
  }

  function clearOrderType() {
    fulfillmentMode.value = FulfillmentMode.DINE_IN;
    tableNumber.value = '';
    orderTypes.value = [
      { icon: 'eat-here', label: 'EAT_HERE', value: FulfillmentMode.DINE_IN },
      { icon: 'shopping-bag', label: 'TAKE_AWAY', value: FulfillmentMode.PICKUP },
    ];
  }

  return {
    orderTypes,
    orderOptions,
    orderType,
    tableNumber,
    fulfillmentMode,
    isPickupOrder,
    setOrderOptions,
    setTableNumber,
    clearOrderType,
  };
});
