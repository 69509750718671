import { LanguageLocales } from '@/modules/language/types';
import { IKioskLanguage } from '@/modules/language/interfaces';

export const useLanguageStore = defineStore('language', () => {
  // State
  const languages = ref<IKioskLanguage[]>([]);
  const currentLanguage = ref<LanguageLocales>('es');
  const isLanguageEditable = ref<boolean>(false);
  const metadataStoreV2 = useMetadataStoreV2();

  // Actions
  function setLanguage(value: LanguageLocales) {
    currentLanguage.value = value;
    i18n.global.locale.value = value;
  }

  function setLanguages(list: IKioskLanguage[]) {
    languages.value = list;
  }

  function setEditableValue(value: boolean) {
    isLanguageEditable.value = value;
  }

  function setup() {
    const kioskLanguages = metadataStoreV2.kioskSettings?.languages;
    if (!kioskLanguages) {
      console.warn('You need to fetch the metadata before setting up the root');
      return;
    }
    // Adapt enabled languages to language store
    languages.value = kioskLanguages.enabled.map((lang) => ({
      id: lang.toLowerCase() as LanguageLocales,
      label: languagesLabels[lang],
    }));

    // Set default language
    setLanguage(kioskLanguages.default?.toLowerCase() as LanguageLocales ?? 'es');
    setEditableValue(kioskLanguages.enabled.length > 1);
  }

  return {
    languages,
    currentLanguage,
    isLanguageEditable,
    setLanguage,
    setLanguages,
    setEditableValue,
    setup,
  };
});
