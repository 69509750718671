import { equals,
  ItemDescriptor,
  quantifiable,
  selectable } from '@slabcode/hubster-modifiers-utils';
import { ComponentPublicInstance } from 'vue';
import { hasUpgradeSelected } from '../utils/hasUpgradeSelected';
import { ICartItem } from '@/modules/cart/interfaces/cartState';

export function useCustomize(component?: ComponentPublicInstance | null) {
  const router = useRouter();
  const metadataStoreV2 = useMetadataStoreV2();
  const { brandSettings } = storeToRefs(metadataStoreV2);

  const showPaginatedMG = computed(() => brandSettings.value?.layout?.modifiers === 'steps');

  const customizeItemStore = useCustomizeItemStore();
  const { item, upgradeModifier, itemEditionIndex } = customizeItemStore;
  const cartStore = useCartStoreV2();

  function startCustomization(itemId: string) {
    customizeItemStore.setupItem(itemId);
    router.push({
      name: showPaginatedMG.value ? 'StepCustomize' : 'DropdownCustomize',
    });
  }

  function continueCustomization(_item: ItemDescriptor, index: number) {
    customizeItemStore.itemEditionIndex = index;
    customizeItemStore.setupItemEdition(_item);
    router.push({
      name: showPaginatedMG.value ? 'StepCustomize' : 'DropdownCustomize',
    });
  }

  const filteredModifiers = item?.modifiers!.filter((m) => !modifierIsUpgrade(m.modifierMeta.name))
    || [];

  function pushCustomization(_item: ItemDescriptor) {
    customizeItemStore.pushModifier(_item);
  }

  function saveCustomization() {
    customizeItemStore.popAndSaveModifier();
    component?.$forceUpdate();
  }

  function cancelCustomization() {
    customizeItemStore.popModifier();
  }

  const shouldDisplayUpgrade = ref(false);
  function checkUpgrade() {
    if (itemEditionIndex !== null) return false;
    if (upgradeModifier) {
      shouldDisplayUpgrade.value = true;
      return true;
    }
    return false;
  }

  function acceptUpgrade() {
    const upgradeItem = upgradeModifier!.items[0];
    if (!selectable(upgradeItem.actions)) return;
    if (!upgradeItem.selected) upgradeItem.actions.select();
  }

  function rejectUpgrade() {
    const upgradeItem = upgradeModifier!.items[0];
    if (!selectable(upgradeItem.actions)) return;
    if (upgradeItem.selected) upgradeItem.actions.select();
  }

  const { triggerScaleUpItem, triggerAddToCart } = useGTMEventsComposableV2();
  const route = useRoute();

  function abortCustomization() {
    const isProductEdition = customizeItemStore.isEditing;
    customizeItemStore.finishCustomization();
    customizeItemStore.$dispose();
    router.replace({ name: isProductEdition ? 'CartV2' : 'OrderProductsView' });
  }

  function getCartItemReplica(): ICartItem | undefined {
    const existingItems = cartStore.items.filter(
      (_item) => _item.item.itemMeta.id === item?.itemMeta.id,
    );
    return existingItems.find((existingItem) =>
      equals(existingItem.item, item!));
  }

  const { triggerEditVariation } = useGTMEventsComposableV2();
  function saveNewItem() {
    const itemReplica = getCartItemReplica();
    if (itemReplica) {
      if (quantifiable(itemReplica.item.actions)) {
        itemReplica.item.actions.increase();
        triggerEditVariation({
          history: customizeItemStore.history,
          isEdit: false,
          cartItem: toGTMCartItem(itemReplica),
        });
        cartStore.updateCart();
      }
    } else {
      const addedItem = cartStore.addItem(item!);
      triggerAddToCart({
        item: toGTMCartItem(addedItem),
        section: route.name,
        operation: CartEventType.ADD,
      });
      if (hasUpgradeSelected(addedItem.item)) {
        triggerScaleUpItem({
          identifier: addedItem.identifier,
          isEdit: false,
          item: addedItem.item.itemMeta,
          value: addedItem.item.itemMeta.price.amount,
        });
      }
      triggerEditVariation({
        history: customizeItemStore.history,
        isEdit: false,
        cartItem: toGTMCartItem(addedItem),
      });
    }
  }

  function editItem() {
    customizeItemStore.saveChanges?.();
    cartStore.updateItemDescription(customizeItemStore.itemEditionIndex!);
    const editedItem = customizeItemStore.item!;
    const cartItem = cartStore.items[customizeItemStore.itemEditionIndex!];
    triggerEditVariation({
      history: customizeItemStore.history,
      isEdit: true,
      cartItem: toGTMCartItem(cartItem),
    });
    triggerAddToCart({
      item: toGTMCartItem(cartItem),
      section: route.name,
      operation: CartEventType.EDIT,
    });
    if (hasUpgradeSelected(editedItem)) {
      triggerScaleUpItem({
        identifier: cartItem.identifier,
        isEdit: true,
        item: editedItem.itemMeta,
        value: editedItem.itemMeta.price.amount,
      });
    }
  }

  function finishCustomization() {
    const isProductEdition = customizeItemStore.isEditing;
    if (!isProductEdition) {
      saveNewItem();
    } else if (isProductEdition) {
      editItem();
    }
    customizeItemStore.finishCustomization();
    customizeItemStore.$reset();

    if (!isProductEdition) {
      router.replace({ name: 'OrderProductsView' });
    } else if (isProductEdition) {
      router.replace({ name: 'CartV2' });
    }
  }

  const menuStore = useMenuStore();
  const alcoholInfo = storeToRefs(menuStore).alcohol;
  function filterItemsWithAlcohol(items: ItemDescriptor[]) {
    const itemsWithoutAlcohol = items.filter(
      (_item) => !_item.itemMeta.skuDetails.containsAlcohol,
    );
    return alcoholInfo.value.allow ? items : itemsWithoutAlcohol;
  }

  return {
    startCustomization,
    continueCustomization,
    item,
    filteredModifiers,
    shouldDisplayUpgrade,
    pushCustomization,
    saveCustomization,
    cancelCustomization,
    acceptUpgrade,
    checkUpgrade,
    finishCustomization,
    filterItemsWithAlcohol,
    rejectUpgrade,
    getModifierClass,
    abortCustomization,
  };
}
