<script setup lang="ts">
const metadataStoreV2 = useMetadataStoreV2();
const { brandSettings } = storeToRefs(metadataStoreV2);

defineProps({
  disableIncrease: {
    type: Boolean,
    default: false,
  },
  disableDecrease: {
    type: Boolean,
    default: false,
  },
  buttonStyle: {
    type: String,
    required: false,
    default: 'fill',
  },
  buttonClass: {
    type: String,
    required: false,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  quantity: {
    type: Number,
    default: 0,
  },
});

defineEmits(['add', 'sub']);
</script>

<template>
  <div
    :class="{
      'opacity-50': disabled,
    }"
    class="kfc-counter"
  >
    <KioskButton
      type="button"
      class="counter-button !text-gray-500 !border-gray-300"
      :disabled="disableDecrease || disabled"
      color="outline"
      @on-click="$emit('sub')"
    >
      <span class="text-xl icon icon-minus" />
    </KioskButton>

    <span
      class="w-8 text-3xl font-medium text-center"
      :class="{ 'w-14 h-14 px-4 flex items-center justify-center rounded-xs border-2 border-neutral-200/50': buttonStyle === 'outline' }"
    >
      {{ quantity }}
    </span>

    <KioskButton
      :color="brandSettings?.buttons.counterStyle === 'fill' ? 'primary' : 'outline'"
      type="button"
      class="counter-button"
      :disabled="disableIncrease || disabled"
      @on-click="$emit('add')"
    >
      <span class="text-xl icon icon-plus" />
    </KioskButton>
  </div>
</template>

<style>
.kfc-counter {
  @apply flex items-center gap-4 p-4 text-2xl;
}

.counter-button {
  @apply !border-2 !h-12 !w-12 !min-h-12 !p-2.5 text-4xl font-bold;
}
</style>
