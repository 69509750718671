<script lang="ts" setup>
import { RouteName } from '@/common/routes/routeName';
import { LanguageLocales } from '@/modules/language/types';

const router = useRouter();
const menuStore = useMenuStore();
const kioskImagesStore = useKioskImagesStore();
const metadataStoreV2 = useMetadataStoreV2();
const languageStore = useLanguageStore();
const maintenanceStore = useMaintenanceStore();
const standbyBannerStore = useStandbyBannerStore();
const fulfillmentController = useFulfillmentController();
const gtmStore = useGtmStore();

const { kioskSettings } = storeToRefs(metadataStoreV2);
const { banners64 } = storeToRefs(kioskImagesStore);
const { isLanguageEditable, languages } = storeToRefs(languageStore);

async function startOrder(lang?: LanguageLocales) {
  const redirectTo = await maintenanceStore.getKioskStatus();

  if (lang) {
    languageStore.setLanguage(lang);
  }

  if (redirectTo) {
    router.push({ name: redirectTo });
    return;
  }

  fulfillmentController.redirectFromWelcome();
}

/**
 * TODO: Migrate this logic into composable
 */
async function beforeMount() {
  menuStore.reset();
  // Get data again
  gtmStore.endOrder();
}

onBeforeMount(() => beforeMount());

onBeforeRouteLeave((currentRoute) => {
  // Just preserve the checking of its close in standby banner and welcome view
  if (currentRoute.name !== RouteName.STAND_BY_BANNER) {
    standbyBannerStore.stopChecking();
  }
});
</script>

<template>
  <button
    class="relative full-hd"
    type="button"
    @click="startOrder()"
  >
    <div
      v-if="banners64?.length > 0"
      class="absolute top-0 z-0"
    >
      <ImageCarousel
        :data-images="banners64"
        tailwind-classes="full-hd"
      />
    </div>

    <div class="menu-container">
      <template v-if="isLanguageEditable">
        <KioskButton
          v-for="languageItem in languages"
          :key="languageItem.id"
          class="menu-button"
          color="primary"
          text-size="large"
          :disabled="maintenanceStore.loading"
          @on-click.self="startOrder(languageItem.id)"
        >
          {{ languageItem.label }}
        </KioskButton>
      </template>

      <template v-if="!isLanguageEditable && kioskSettings?.options.startButton">
        <KioskButton
          class="menu-button"
          color="primary"
          text-size="large"
          :disabled="maintenanceStore.loading"
          @on-click.self="startOrder()"
        >
          {{ $t('ORDER_HERE') }}
        </KioskButton>
      </template>
    </div>
  </button>
</template>

<style scoped>
.menu-container {
  @apply flex gap-12 absolute bottom-56 w-full justify-center;
}
.menu-button {
  @apply min-w-[320px];
}
</style>
