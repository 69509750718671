import { CssVariables } from '../enums/cssVariables';

export function useCssVariable() {
  /**
   * Darkens a CSS variable by reducing its brightness.
   * @param cssVariable - The CSS variable to darken.
   * @returns The darkened CSS variable value.
   */
  const darken = (cssVariable: CssVariables, amount = -20) => {
    const variableHex = getComputedStyle(document.documentElement)
      .getPropertyValue(cssVariable);
    return newShade(variableHex, amount);
  };

  /**
   * Lightens a CSS variable by 20%.
   *
   * @param cssVariable - The CSS variable to lighten.
   * @returns The lightened CSS variable.
   */
  const lighten = (cssVariable: CssVariables, amount = 20) => {
    const variableHex = getComputedStyle(document.documentElement)
      .getPropertyValue(cssVariable);
    return newShade(variableHex, amount);
  };

  return { darken, lighten };
}
