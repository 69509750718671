<script setup lang="ts">
defineProps({
  minSelections: {
    type: Number,
    required: true,
  },
  maxSelections: {
    type: Number,
    required: true,
  },
  currentSelections: {
    type: Number,
    required: true,
  },
});

const showAnimation = defineModel({ default: false });
</script>

<template>
  <div class="flex items-center gap-2 modifier-counter">
    <span v-if="minSelections === 0" class="optional-label">
      {{ $t("OPTIONAL") }}
    </span>

    <span
      class="counter-span bg-kiosk-primary"
      :class="{ 'animate__animated animate__headShake': showAnimation }"
      @animationend="showAnimation = false"
    >
      {{ `${currentSelections}/${maxSelections}` }}
    </span>
  </div>
</template>

<style scoped>
.optional-label {
  @apply text-neutral-400 capitalize bg-neutral-100 font-bold px-1 text-4xl rounded h-10 flex justify-center items-center;
}

.counter-span {
  @apply flex justify-center items-start w-20 h-10 leading-10 text-kiosk-btnPrimaryText border-none rounded px-1 font-bold;
}
</style>
