<script lang="ts" setup>
import { PropType } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { HubsterCategory } from '@slabcode/hubster-models/hubster/common';
import { ProductsCategory } from '../interfaces/productsCategory';
import noPhotoSrc from '@/assets/images/noPhoto.jpeg?url';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

const props = defineProps({
  menuItems: {
    type: Array as PropType<ProductsCategory[]>,
    required: true,
  },
});

const emit = defineEmits<{
  goBack: [],
  selectCategory: [category: HubsterCategory],
}>();

const sliderEl = ref();
const menuStore = useMenuStore();
const kioskImagesStore = useKioskImagesStore();
const { currentCategory } = storeToRefs(menuStore);
const { customImages64 } = storeToRefs(kioskImagesStore);

watch(
  currentCategory,
  (newValue) => {
    if (sliderEl.value && newValue) {
      const index = props.menuItems.findIndex((c) => c.id === newValue.id);
      const isVisible = sliderEl.value.visibleSlidesIndexes?.includes(index);
      if (!isVisible) sliderEl.value.slideTo(index);
    }
  },
  { deep: true },
);
</script>

<template>
  <nav class="sidebar">
    <button
      type="button"
      class="flex items-center justify-center w-full h-48"
      @click="emit('goBack')"
    >
      <KioskImage
        v-if="customImages64?.brandImage"
        :src="customImages64.brandImage"
        rel="prefetch"
        image-class="max-h-40"
        class="mb-10 logo"
        hide-border
        alt="logo"
      />
    </button>

    <Swiper
      v-if="menuItems.length > 0"
      ref="sliderEl"
      :direction="'vertical'"
      :slides-per-view="9"
      :touch-ratio="0.5"
      :autoplay="false"
      watch-slides-progress
      touch-start-prevent-default
      class="w-full h-[1728px]"
      @swiper="sliderEl = $event"
    >
      <SwiperSlide
        v-for="item in menuItems"
        :key="item.id"
        class="menu-item-button"
        :class="{ 'bg-kiosk-primary/40 !border-kiosk-primary !border-t-0 !text-neutral-600': currentCategory?.id === item.id }"
        @click="emit('selectCategory', item)"
      >
        <KioskImage
          image-class="object-cover object-center w-24 h-24 rounded-md"
          :src="item.description.length ? item.description : noPhotoSrc"
          :alt="item.name"
          hide-border
        />
        <span class="flex items-center mt-2 text-xl leading-none text-center first-capitalize min-h-12">
          {{ item.name }}
        </span>
      </SwiperSlide>
    </Swiper>
  </nav>
</template>

<style scoped>
.logo {
@apply object-cover mb-8;
}

.menu-item-button {
  @apply w-full h-full flex flex-col font-light text-neutral-400 items-center justify-center p-3 border-r-4 border-r-transparent border-t border-b-neutral-200;
}

.top-animation {
  @apply animate-bounce;
}

.sidebar {
  @apply fixed top-0 justify-start items-center bg-white z-20 h-full w-56;
}
</style>
