export function hexToRgb(hex: string) {
  // Ensure the input is a valid hex color
  const validHex = /^#?([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/;

  // Color with opacity
  if (/^#[0-9A-Fa-f]{8}$/.test(hex)) {
    const rgb = hex.substring(1, 7);
    // Convert RGB to individual R, G, B values
    const r = parseInt(rgb.substring(0, 2), 16);
    const g = parseInt(rgb.substring(2, 4), 16);
    const b = parseInt(rgb.substring(4, 6), 16);
    const d = parseInt(rgb.substring(6, 8), 16);
    return `${r}, ${g}, ${b}, ${d}`;
  }

  if (!validHex.test(hex)) {
    throw new Error(`Invalid hex color provided: ${hex}.`);
  }

  // Remove the hash if present
  const sanitizedHex = hex.replace('#', '');

  // Handle short-form hex (#FFF -> #FFFFFF)
  const fullHex = sanitizedHex.length === 3
    ? sanitizedHex
      .split('')
      .map((char) => char + char)
      .join('')
    : sanitizedHex;

  // Convert to RGB values
  const bigint = parseInt(fullHex, 16);
  const r = Math.floor((bigint / (256 * 256)) % 256);
  const g = Math.floor((bigint / 256) % 256);
  const b = bigint % 256;

  // Return rgb string
  return `${r}, ${g}, ${b}`;
}
