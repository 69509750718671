export function useCartUtils() {
  const route = useRoute();
  const cartStore = useCartStoreV2();
  const reset = useReset();
  const gtmStore = useGtmStore();
  const multibrandStore = useMultibrandStore();
  const { triggerCancelOrder } = useGTMEventsComposableV2();

  /**
   * Reset the cart and redirect to first view
   */
  const restartCart = (isModal = false) => {
    triggerCancelOrder({
      items: toGTMCartItems(cartStore.items),
      section: route.name!.toString(),
      isModal,
    });

    if (multibrandStore.multibrand) {
      window.location.href = multibrandStore.backUrl;
      return;
    }

    // Reset transactionId
    gtmStore.endOrder();
    reset.flushState();
  };

  return { restartCart };
}
