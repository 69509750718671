import { rest } from 'msw';
import { METADATA_MOCK } from '../data';

export const updatesRequests = (msw: typeof rest) => [
  msw.get(':storeId/metadata', async (_, res, ctx) => res(
    ctx.status(200),
    ctx.delay(1000),
    ctx.json(METADATA_MOCK),
  )),
];
