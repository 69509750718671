import { RouteName } from '../routes/routeName';

export function useReset() {
  const router = useRouter();
  const route = useRoute();
  const cartStore = useCartStoreV2();
  const webhookStore = useWebhookStoreV2();
  const orderTypeStore = useOrderTypeStore();
  const couponStore = useCouponStore();
  const policiesStore = useLegalPolicies();
  const modalStore = useModalStore();

  const flushState = () => {
    console.warn('reset from:', route.name);
    cartStore?.clearCart?.();
    webhookStore?.restartWebhook?.();
    orderTypeStore?.clearOrderType?.();
    couponStore?.clearCoupon?.();
    policiesStore?.resetPolicies?.();
    modalStore?.closeModal();
    router.push({ name: RouteName.WELCOME });
  };

  return {
    flushState,
  };
}
