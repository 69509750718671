<script lang="ts" setup>
const showModal = ref(false);

defineProps({
  text: {
    type: String,
    required: true,
  },
  modalDescription: {
    type: String,
    required: true,
  },
  modalImage: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
});

const model = defineModel({
  type: Boolean,
  required: true,
});

function openModal() {
  showModal.value = true;
}

function closeModal() {
  showModal.value = false;
}

function accept() {
  model.value = true;
  closeModal();
}
</script>

<template>
  <li>
    <label>
      <input
        v-model="model"
        type="checkbox"
        class="h-7 w-7 accent-kiosk-primary"
        :disabled="disabled"
      />
      <button
        type="button"
        class="underline"
        :disabled="disabled"
        @click="openModal()"
      >
        {{ text }}<span class="text-red-600" v-if="required">*</span>
      </button>
    </label>
  </li>

  <KioskModal modal-class="!w-[600px] !px-0" v-if="showModal">
    <section class="flex flex-col items-center w-full gap-8">
      <h2 class="w-full pb-5 text-3xl text-center border-b-2">
        {{ text }}
      </h2>
      <figure class="flex flex-col items-center max-w-full px-10">
        <img width="350" :src="modalImage" :alt="text">
        <figcaption class="max-w-full text-4xl text-center text-gray-600 break-words">
          {{ modalDescription }}
        </figcaption>
      </figure>
      <div class="flex items-center gap-5">
        <KioskButton @on-click="closeModal()" text-size="small" color="outline">
          {{ $t('BACK') }}
        </KioskButton>
        <KioskButton @on-click="accept()" text-size="small" color="primary">
          {{ $t('ACCEPT') }}
        </KioskButton>
      </div>
    </section>
  </KioskModal>
</template>

<style scoped>
label {
  @apply flex gap-5 items-center text-2xl;
}
</style>
