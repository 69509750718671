import { z } from 'zod';
import * as Sentry from '@sentry/browser';

const itemSchema = z.object({
  name: z.string(),
  description: z.string(),
  modifierGroupIds: z.array(z.string()),
  status: z.object({
    saleStatus: z.string(),
  }),
  price: z.object({
    currencyCode: z.string(),
    amount: z.number(),
  }),
  skuDetails: z.object({
    skuSlug: z.string(),
    barcodes: z.array(z.string()),
    dietaryClassifications: z.array(z.string()),
    allergenClassifications: z.array(z.string()),
    additives: z.array(z.string()),
    storageRequirements: z.array(z.string()),
    containsAlcohol: z.boolean(),
  }),
  additionalCharges: z.array(z.unknown()),
  id: z.string(),
  photoIds: z.array(z.string()),
  priceOverrides: z.array(z.unknown()),
  tax: z.object({
    percentageValue: z.object({
      decimalValue: z.number(),
    }),
    isValueAddedTax: z.boolean(),
  }),
});

// Esquema para una categoría dentro de "categories"
const categorySchema = z.object({
  name: z.string(),
  description: z.string(),
  itemIds: z.array(z.string()),
  id: z.string(),
});

const modifierGroupSchema = z.object({
  name: z.string(),
  minimumSelections: z.number(),
  maximumSelections: z.number(),
  maxPerModifierSelectionQuantity: z.number(),
  itemIds: z.array(z.string()),
  id: z.string(),
});

const menuSchema = z.object({
  menu: z.object({
    categories: z.record(categorySchema),
    items: z.record(itemSchema),
    modifierGroups: z.record(modifierGroupSchema),
  }),
});

export function validateMenu(menu: unknown) {
  const result = menuSchema.safeParse({ menu });
  if (!result.success) {
    Sentry.captureMessage(`Invalid menu schema: \n ${
      JSON.stringify(result.error, null, 2)
    }\n\nURL: ${window.location.href}`);
  }
}
