<script lang="ts" setup>
defineEmits(['showModal']);

const modalStore = useModalStore();
const couponStore = useCouponStore();
const metadataStoreV2 = useMetadataStoreV2();

const { kioskSettings } = storeToRefs(metadataStoreV2);

const { promoCode } = storeToRefs(couponStore);

const showKeyboard = ref(false);
const blockKeyboardToggle = ref(false);
const inputRef = ref<HTMLInputElement | null>(null);

function setCurrentField(event: Event) {
  if (blockKeyboardToggle.value) return;

  inputRef.value = event.target as HTMLInputElement;
  // Show user keyboard
  showKeyboard.value = true;
}

async function validateCoupon() {
  if (!promoCode.value) return;

  showKeyboard.value = false;
  modalStore.showModal = true;

  blockKeyboardToggle.value = true;

  await couponStore.validatePromoCode();
}

function closeModal() {
  couponStore.err = '';
  couponStore.response = null;
  couponStore.promoCode = '';
  blockKeyboardToggle.value = false;

  modalStore.closeModal();
}
</script>

<template>
  <div
    class="fixed z-10 flex items-center justify-center w-full gap-10 py-5 bg-white shadow-neutral-700 bottom-28"
  >
    <input
      v-if="kioskSettings?.coupons.settings.text"
      v-model="promoCode"
      @focus="setCurrentField($event)"
      @keyup.enter="showKeyboard = false"
      :placeholder="$t('WRITE_COUPON_CODE')"
      class="!w-1/2 h-24 text-3xl border rounded-md input input-bordered"
    />

    <!-- eslint-disable-next-line vue/html-self-closing -->
    <div
      v-if="
        !kioskSettings?.coupons.settings.text
          && !kioskSettings?.coupons.settings.qrScanner
      "
      class="w-px h-11 mx-4 bg-[#c1c6ca]"
    ></div>

    <KioskButton
      v-if="kioskSettings?.coupons.settings.qrScanner"
      text-size="base"
      color="outline"
      @on-click="$emit('showModal')"
      class="!h-24"
    >
      {{ $t("SCAN_QR") }}
      <span class="text-5xl icon icon-qr" />
    </KioskButton>
  </div>

  <SlideTransition @before-enter="true" @after-leave="true">
    <CustomKeyboard
      class="fixed bottom-0"
      v-if="showKeyboard"
      v-model="promoCode"
      :show-email-suggestions="false"
      :input-ref="inputRef"
      @close="showKeyboard = false"
      @accept="validateCoupon()"
    >
      <template #input>
        <div class="flex items-center justify-center !w-11/12 gap-5 pl-10 py-2">
          <input
            v-model="promoCode"
            @keyup.enter="showKeyboard = false"
            :placeholder="$t('WRITE_COUPON_CODE')"
            class="w-2/3 !h-24 text-4xl rounded-md input input-bordered"
          />
        </div>
      </template>
    </CustomKeyboard>
  </SlideTransition>

  <CouponModal
    v-if="modalStore.showModal"
    :loading="couponStore.loading"
    :success-response="couponStore.response || undefined"
    :error-response="couponStore.err"
    @close="closeModal()"
  />
</template>
