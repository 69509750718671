/* eslint-disable no-bitwise */
/* eslint-disable no-unused-expressions */
// Extract from google and works :v
export const newShade = (hexColor: string, magnitude: number) => {
  const newHexColor = hexColor.replace('#', '');
  if (newHexColor.length === 6) {
    const decimalColor = parseInt(newHexColor, 16);
    let r = (decimalColor >> 16) + magnitude;
    r > 255 && (r = 255);
    r < 0 && (r = 0);
    let g = (decimalColor & 0x0000ff) + magnitude;
    g > 255 && (g = 255);
    g < 0 && (g = 0);
    let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
    b > 255 && (b = 255);
    b < 0 && (b = 0);
    return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
  }
  return newHexColor;
};
