import { StepWizardName } from '../enums/stepWizardName';
import { SelectOption } from '../interfaces';

export const useStepWizard = () => {
  const metadataStoreV2 = useMetadataStoreV2();
  const { brandSettings } = storeToRefs(metadataStoreV2);

  const showPaginatedMG = computed(() => brandSettings.value?.layout?.modifiers === 'steps');

  const { t } = useI18n();

  const steps = computed((): SelectOption[] => {
    const stepList = [
      t('TIMELINE.CHOOSE_PRODUCT'),
      t('TIMELINE.CUSTOMIZATION'),
      t('TIMELINE.CONFIRM'),
      t('TIMELINE.PAY'),
    ];

    if (showPaginatedMG.value) stepList.splice(1, 1);

    return stepList.map((i) => ({ label: i, value: i }));
  });

  function stepIndex(step: StepWizardName) {
    const resultDic = {
      [StepWizardName.CHOOSE_PRODUCT]: 0,
      [StepWizardName.CUSTOMIZE]: 1,
      [StepWizardName.CONFIRM]: showPaginatedMG.value ? 1 : 2,
      [StepWizardName.PAY]: showPaginatedMG.value ? 2 : 3,
    };
    return resultDic[step];
  }

  return {
    steps,
    stepIndex,
  };
};
